<template>
  <div class="create_poster_container">
    <header class="header">
      <van-nav-bar
          title="添加海报"
          left-arrow
          @click-left="handleBlack"
          safe-area-inset-top
      >
      </van-nav-bar>
    </header>

    <article class="article">
      <div class="article_info">
        <span>海报标题</span>
        <van-field
            v-model="poster.posterName"
            rows="2"
            autosize
            type="textarea"
            placeholder="标题字数建议在8字以内"
            show-word-limit
        />
        <span>上传海报<span class="poster-upload-left">二维码将固定在海报底部</span></span>
        <div class="article_img">
          <image-upload
              v-if="loading"
              :busiId="busiId"
              busiCat="poster"
              groupId="posterPic"
          ></image-upload>
          <span class="poster-upload-left">建议尺寸：750×1334</span>
        </div>
        <span style="margin-top: 16px">二维码链接</span>
        <van-field
            v-model="poster.jumpUrl"
            rows="2"
            autosize
            type="textarea"
            placeholder="请从其他页面复制链接粘贴，否则不会生成二维码"
            show-word-limit
        />
        <div class="moveto">
          <span @click="handleCopyUrl('article')">文章</span>
          <span @click="handleCopyUrl('cases')">设计</span>
          <span @click="handleCopyUrl('video')">短视频</span>
          <span @click="handleCopyUrl('buildSite')">工地</span>
<!--          <span @click="handleCopyUrl('card')">名片</span>-->
        </div>
      </div>
      <div class="footer">
        <van-grid clickable :column-num="2" safe-area-inset-bottom>
          <van-grid-item
          ><van-button
              round
              type="primary"
              block
              color="#BFBFBF"
              @click="handleBlack"
          >返回</van-button
          ></van-grid-item
          >
          <van-grid-item
          ><van-button
              round
              type="primary"
              block
              color="#FF6E01"
              @click="handleCreatePoster"
          >保存</van-button
          ></van-grid-item
          >
        </van-grid>
      </div>
    </article>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import ImageUpload from "../../components/FileUpload/ImageUpload.vue";
import {findNextId,createPosterApp} from "../../api/posterCenter"
import { Toast } from "vant";
export default {
  name:'addPoster',
  components: {
    ImageUpload,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      busiId:'',
      loading: false,
      poster:{
        posterId:'',
        posterName:'',
        jumpUrl:'',
      }
    });
    const getPosterId = async () =>{
      let res = await findNextId()
      if (res){
        console.log("res=======",res)
        state.poster.posterId = res
        state.busiId = res
        state.loading = true
      }
    }
    getPosterId()
    const handleBlack = () =>{
      // router.back()
      router.push({
        path:'/posterVisitors',
        query:{
          isCreate:true,
        }
      })
    }
    const handleCreatePoster = async () =>{
      let  params = {
        posterId:state.poster.posterId,
        posterName:state.poster.posterName,
        jumpUrl:state.poster.jumpUrl
      }
      // if(state.poster.jumpUrl){
        if(state.poster.posterName){
          let res = await createPosterApp(params)
          if(res){
            // await router.push('/posterVisitors')
            await router.push({path:'/posterVisitors',query:{
                isCreate:1,
              }})
            console.log("保存结果",res)
          }
        }else {
          Toast.fail("海报名字不能为空")
        }
      // }else {
      //   Toast.fail("二维码链接不能为空")
      // }

    }
    const handleCopyUrl = (val)=>{
      switch (val){
        case 'article':
          router.push({
            path:'/articleVisitors',
            name:'articleVisitors',
            params:{
              fromPoster:true,
              addPoster:true
            }
          })
          break;
        case 'cases':
          router.push({
            path:'/caseVisitors',
            name:'caseIndex',
            params:{
              fromPoster:true,
              addPoster:true
            }
          })
          break;
        case 'video':
          router.push({
            path:'/videoVisitors',
            name:'video',
            params:{
              fromPoster:true,
              addPoster:true
            }
          })
          break;
        case 'buildSite':
          router.push({
            path:'/buildVisitors',
            name:'buildVisitors',
            params:{
              fromPoster:true,
              addPoster:true
            }
          })
          break;
        case 'card':
          router.push({
            path:`/userCard`,
            name:'userCard',
            params:{
              fromPoster:true
            },
            query:{
              hideTitleBar:99,
            }
          })
          break;
        default:
          break;
      }
    }
    return {
      handleCreatePoster,
      handleBlack,
      ...toRefs(state),
      handleCopyUrl};
  },
};
</script>
<style lang="scss">
.create_poster_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f6;

  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 400;
    }
  }
  .article {
    flex: 1;
    overflow-x: hidden;
    .article_info {
      padding: 17px;
      text-align: left;
      border-radius: 5px;
      > span {
        color: rgba(0, 0, 0, 0.84);
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 7px;
      }
      > span:nth-child(3) {
        display: block;
        margin-top: 30px;
      }
      .poster-upload-left{
        color: #BFBFBF;
        font-weight: 400;
        font-size: 10px;
        margin-left: 10px;
      }
      .moveto{
        display: flex;
        font-size: 14px;
        color: #289FFF;
        padding: 5px;
        justify-content: space-around;
      }
    }
    .button_confirm {
      display: flex;
      justify-content: space-between;
      padding: 16px;

      > div {
        width: 160px;
        height: 44px;
        color: #fff;
        font-size: 14px;
        border-radius: 8px;
        text-align: center;
        line-height: 44px;
        background-color: #bfbfbf;
      }
      .button_confirm_save {
        background: linear-gradient(0deg, #ff6e01, #ff6e01);
      }
    }
    .footer {
      .van-button--round {
        border-radius: 8px;
        height: 44px;
        width: 160px;
      }
      .van-hairline {
        background-color: #f7f7f7;
      }
    }
  }
}
</style>
